import React, { Component , Fragment } from "react";
import Slider from "react-slick";
import { slickDot } from "../page-demo/script";
import Scrollspy from 'react-scrollspy';
import ScrollToTop from 'react-scroll-up';
import { FiHeadphones, FiMail, FiMapPin, FiChevronUp , FiX , FiMenu } from "react-icons/fi";
import ServiceList from "../elements/service/ServiceList";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import Team from "../elements/Team";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import FooterTwo from "../component/footer/FooterTwo";
import Contact from "../elements/contact/ContactTwo";
import Helmet from "../component/common/Helmet";

import GoogleMapReact from 'google-map-react';

import ContactTwo from "../elements/contact/ContactTwo";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

const AnyReactComponent = ({ text }) => <div className="map-marker"><FiMapPin /></div>;

const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'European Union Funding ',
        description: 'European Union Funding for Startups, Companies & Projects €2,5 - €15 mln',
        buttonText: 'Contact Us',
        buttonLink: '#contact'
    }
]

const list = [
    {
        image: 'image-1',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-2',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-3',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-4',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-3',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-4',
        category: 'Development',
        title: 'Getting tickets to the big show'
    }
]


class CreativeLanding extends Component{
    static defaultProps = {
        center: {
            lat: 50.8427199,
            lng: 4.3109227
        },
        zoom: 11
    };

    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader () {}
    render(){
        const PostList = BlogContent.slice(0 , 5);
        
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        
        return(
            <Fragment>
                <Helmet pageTitle="European Union Funding" />
                {/* Start Header Area  */}
                <header className="header-area formobile-menu header--fixed default-color">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                   <h5 style={{marginBottom: "0px"}}>European Union Funding</h5>
                                </a>
                            </div>
                        </div>
                        <div className="header-right">
                            <nav className="mainmenunav d-lg-block">
                                <Scrollspy className="mainmenu" items={['home', 'experience', 'about', 'contact']} currentClassName="is-current" offset={-200}>
                                    <li><a href="#home">Home</a></li>
                                    <li><a href="#experience">Our Experience</a></li>
                                    <li><a href="#about">What we do for you</a></li>
                                    <li><a href="#contact">Contact</a></li>
                                </Scrollspy>
                            </nav>
                            {/* Start Humberger Menu  */}
                            <div className="humberger-menu d-block d-lg-none pl--20">
                                <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                            </div>
                            {/* End Humberger Menu  */}
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-activation slider-creative-agency" id="home">
                    <div className="bg_image bg_image--26" data-black-overlay="6">
                        {SlideList.map((value , index) => (
                            <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className={`inner ${value.textPosition}`}>
                                                {value.category ? <span>{value.category}</span> : ''}
                                                {value.title ? <h1 className="theme-gradient">{value.title}</h1> : ''}
                                                {value.description ? <p className="description">{value.description}</p> : ''}
                                                {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* End Slider Area   */}


                {/* Start About Area */}
                <div className="about-area ptb--120 bg_color--5" id="experience">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-3.jpg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">Our Experience</h2>
                                            <p className="description">We can assist in applying for a non-returnable European Union public grants up to €2,5 mln and further possible equity investments of up to €15 mln.</p>

<p className="description">There is a lack of finance for breakthrough and disruptive innovators in Europe, with an estimated total equity-funding gap of about €70 billion. Many European start-ups can’t find high risk capital needed to get to the stage where private sector investors get involved.</p>
<h4 className="title">We can help!</h4>
                                                <p className="description">For development and scaling up of high risk innovations by startups and SMEs</p>
                                                    <p className="description">
                                                    <ul><li>Grant funding of up to €2.5 million (No returnable Grant! which means you do not have to return the funds)</li>
                                                    <li>Option of equity investment of up to €15 million</li>
                                                    <li>Access to coaching and mentoring services, pitches with corporates, VCs, etc</li>
                                                    <li>All funding is open (no predefined thematic areas)</li>
                                                    </ul>
                                                </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start About Area */}
                <div className="about-area ptb--120 bg_color--1" id="about">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">What we do for you! </h2>
<p className="description">
We investigate and choose the best possible European Union Grant suitable for your business.
</p>
<p className="description">
Once you are approved....
</p>
<p className="description">
High possibility of our application to receive up to €2,5 mln non refundable grant<br/>
High possibility of our application to receive up to €15 mln equity investment <br/>
We prepare an Advanced excel financial model<br/>
We prepare a European Commission standard Business plan<br/>
We prepare a 30 pages word proposal<br/>
Registration of your company in the beneficiary register of the European Commission<br/>
We submit your proposal <br/>
Project re-submission up to three times<br/>
Free coaching from high level advisor part of the EU network <br/>
Face – To – Face interview simulation with our European advisors<br/>
<br/>
EU Accepted projects will be considered for an additional investment from our family office fund.<br/>
<br/>
<a href="mailto:ian@ianscarffe.com">email Ian Scarffe for more details ian@ianscarffe.com</a><br/><br/></p>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-4.jpg" alt="About Images"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}


                {/* Start Contact Us */}
                <div className="rn-contact-us ptb--60 bg_color--5" id="contact">
                     {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--60 bg_color--5">
                    <div className="container">
                       
                        <div className="row">
                          
                            {/* Start Single Address  */}
                            <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Email Address</h4>
                                        <p><a href="mailto:info@europeanunionfunding.com">info@europeanunionfunding.com</a></p>
                                        <p><a href="mailto:ian@ianscarffe.com">ian@ianscarffe.com</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}
                
                </div>
                {/* End Contact Us */}
                {/* Start Contact Map  */}
                <div className="rn-contact-map-area position-relative">
                    <div style={{ height: '650px', width: '100%' }}>
                        <GoogleMapReact
                        apiKey="AIzaSyDuEIbrBhJ9YmsnJPT6jhpIo1Hj7PC3Lik"
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                        >
                        <AnyReactComponent
                            lat={50.8427199}
                            lng={4.3109227}
                            text="My Marker"
                        />
                        </GoogleMapReact>
                    </div>
                </div>
                {/* End Contact Map  */}



                 {/* Start Footer Style  */}
                 <FooterTwo />
                {/* End Footer Style  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </Fragment>
        )
    }
}

export default CreativeLanding;